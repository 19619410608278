import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import lowerFirst from 'lodash/lowerFirst';
import camelCase from 'lodash/camelCase';
import { ListIteratee } from 'lodash';

export const mapKeysDeep = (
  cb: ListIteratee<unknown>,
  currentKey: string,
  pathsToSkip: string[] = [],
) => (obj: unknown): unknown => {
  if (!isPlainObject(obj)) return obj;

  return mapValues(mapKeys(obj as never, cb), (val, key) => {
    if (isPlainObject(val)) {
      const newCurrentKey = currentKey ? `${currentKey}.${key}` : key;

      if (pathsToSkip.includes(newCurrentKey)) {
        return val;
      }

      return mapKeysDeep(cb, newCurrentKey, pathsToSkip)(val);
    }

    if (isArray(val)) {
      const newCurrentKey = currentKey ? `${currentKey}.${key}[]` : `${key}[]`;

      if (pathsToSkip.includes(newCurrentKey)) {
        return val;
      }

      return map(val, (v) => mapKeysDeep(cb, newCurrentKey, pathsToSkip)(v));
    }

    return val;
  });
};

const makeCaseDeep = (caseTransformer: (key: string) => string) => (
  value: unknown,
  pathsToSkip: string[] = [],
): unknown => {
  const caseTransformerFn: ListIteratee<unknown> = (_, key: string): string =>
    lowerFirst(caseTransformer(key));

  return isArray(value)
    ? value.map(mapKeysDeep(caseTransformerFn, '', pathsToSkip))
    : mapKeysDeep(caseTransformerFn, '', pathsToSkip)(value);
};

export const cameliseDeep = makeCaseDeep(camelCase);
