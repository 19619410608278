import set from 'lodash/set';
import { GetStaticProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { removeUndefinedFields } from 'utils/lodash/client';
import { PageFooterProps, PageHeaderProps } from './types';
import {
  HEADER_PROPS_PAGE_PROPS_KEY,
  FOOTER_PROPS_PAGE_PROPS_KEY,
} from './consts';
import { getPrismicConfig } from '.';
import { tryGetRefFromPreviewData } from './utils';

export const getStaticPropsWithHeaderAndFooterProps = <
  T,
  Q extends ParsedUrlQuery = ParsedUrlQuery
>(
  getStaticProps: GetStaticProps<T, Q>,
): GetStaticProps<T, Q> => async (context) => {
  const innerGetStaticPropsResult = await getStaticProps(context);

  if ('props' in innerGetStaticPropsResult) {
    const {
      headerItems,
      footerColumns,
      footerCopy,
      disclaimer,
      topBannerContent,
      topBannerActive,
      hostAtmLinkLabel,
      hostAtmLink,
    } = await getPrismicConfig({
      locale: context.locale,
      ref: context.preview
        ? tryGetRefFromPreviewData(context.previewData)
        : undefined,
    });
    const footerProps: PageFooterProps = removeUndefinedFields({
      copy: footerCopy,
      disclaimer,
      columns: footerColumns,
    });
    const headerProps: PageHeaderProps = removeUndefinedFields({
      navItems: headerItems,
      topBannerContent: topBannerActive ? topBannerContent : undefined,
      hostAtmLinkLabel,
      hostAtmLink,
    });

    set(
      innerGetStaticPropsResult,
      `props.${HEADER_PROPS_PAGE_PROPS_KEY}`,
      headerProps,
    );
    set(
      innerGetStaticPropsResult,
      `props.${FOOTER_PROPS_PAGE_PROPS_KEY}`,
      footerProps,
    );
  }

  return innerGetStaticPropsResult;
};
