import { getCacheProvider } from '.';
import { CacheEntryOptions } from './types';

export const getCachedValueWithFallback = async <T>({
  key,
  fallback,
  cacheOptions,
  forceCacheRefresh = false,
}: {
  key: string;
  fallback: () => Promise<T>;
  cacheOptions?: CacheEntryOptions;
  forceCacheRefresh?: boolean;
}): Promise<T> => {
  const cacheProvider = getCacheProvider();
  const cachedValue = forceCacheRefresh
    ? undefined
    : ((await cacheProvider.get(key)) as T | undefined);

  if (cachedValue) {
    return cachedValue;
  }

  const valueToCache = await fallback();

  await cacheProvider.set(key, valueToCache, cacheOptions);

  return valueToCache;
};
