import styled from '@emotion/styled';
import { Flex } from 'theme-ui';

export const StyledNotFoundPageWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
  text-align: center;
  flex-grow: 1;
`;
