//////////////////////
//////////////////////////
//////////
import { CacheEntry, CacheEntryOptions, CacheProvider } from './types';

interface FsCacheEntry {
  expires?: number;
  value: unknown;
}

const CACHE_FILE_PATH = './.cache/fs-cache.json';

export default class FileSytemCacheProvider implements CacheProvider {
  // eslint-disable-next-line class-methods-use-this
  private async getFsCache(): Promise<Partial<MappedObject<FsCacheEntry>>> {
    const fsCache = fs.readJSONSync(CACHE_FILE_PATH, {
      throws: false,
    });
    const cacheJson = fsCache || {};

    return typeof cacheJson === 'object' ? cacheJson : {};
  }

  // eslint-disable-next-line class-methods-use-this
  private async setFsCache(
    cache: Partial<MappedObject<FsCacheEntry>>,
  ): Promise<void> {
    fs.ensureFileSync(CACHE_FILE_PATH);
    fs.writeJsonSync(CACHE_FILE_PATH, cache);
  }

  async get(key: string): Promise<unknown> {
    const cacheEntry = (await this.getFsCache())[key];

    if (!cacheEntry) {
      return undefined;
    }

    const { value, expires } = cacheEntry;
    const hasExpired =
      expires !== undefined ? new Date(expires).valueOf() < Date.now() : false;

    if (hasExpired) {
      await this.remove(key);

      return undefined;
    }

    return value;
  }

  async set(
    key: string,
    value: unknown,
    options?: CacheEntryOptions,
  ): Promise<void> {
    const { maxAge } = options || {};
    const expires =
      maxAge !== undefined ? new Date(Date.now() + maxAge) : undefined;
    const cache = await this.getFsCache();

    cache[key] = {
      expires: expires?.valueOf(),
      value,
    };

    await this.setFsCache(cache);
  }

  async remove(key: string): Promise<void> {
    const cache = await this.getFsCache();

    delete cache[key];

    await this.setFsCache(cache);
  }

  async removeAll(): Promise<void> {
    await this.setFsCache({});
  }

  async peek(): Promise<CacheEntry[]> {
    return Object.entries(await this.getFsCache()).reduce<CacheEntry[]>(
      (result, [key, fsCacheEntry]) => {
        if (!fsCacheEntry) {
          return result;
        }

        const { value } = fsCacheEntry;

        return [
          ...result,
          {
            key,
            value,
          },
        ];
      },
      [],
    );
  }
}
