import FileSytemCacheProvider from './FileSytemCacheProvider';
import MemoryCacheProvider from './MemoryCacheProvider';
import { CacheProvider } from './types';

let cacheProviderInstance: CacheProvider;

export const getCacheProvider = (): CacheProvider => {
  const { npm_lifecycle_event: npmLifecycleEvent, NODE_ENV } = process.env;
  const CacheProviderClass =
    NODE_ENV === 'development' || npmLifecycleEvent === 'build'
      ? FileSytemCacheProvider
      : MemoryCacheProvider;

  if (
    !cacheProviderInstance ||
    !(cacheProviderInstance instanceof CacheProviderClass)
  ) {
    cacheProviderInstance = new CacheProviderClass();
  }

  return cacheProviderInstance;
};
