import Prismic from '@prismicio/client';
import { v4 as uuidv4 } from 'uuid';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import { RichText } from 'prismic-reactjs';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import truncate from 'lodash/truncate';
import take from 'lodash/take';

import { cameliseDeep } from 'utils/lodash/server';
import { findKeyByValue, removeUndefinedFields } from 'utils/lodash/client';

import { SelectItem } from 'components/atoms/Fields/Select/types';
import { getCachedValueWithFallback } from 'setup/cache/utils';
import { NavItem } from 'components/organisms/Header/partials/Nav/types';
import { SubMenuItem } from 'components/organisms/Header/partials/SubMenu/types';
import routes from 'setup/consts/routes';
import {
  BlogPostData,
  CMSContentBody,
  CMSContentPageSection,
  ColumnContentBody,
  ColumnContentPageSection,
  ConfigData,
  CTAPageSection,
  CTASectionBody,
  DocumentWithAlternateLanguages,
  ETransferFormBody,
  ETransferFormPageSection,
  FaqBody,
  FaqPageSection,
  FeaturedBlogPostsBody,
  FeaturedBlogPostsPageSection,
  FeaturesPageSection,
  FeaturesSectionBody,
  FlexepinFormHeroBody,
  FlexepinFormHeroPageSection,
  HeroBody,
  HeroPageSection,
  HostATMFormBody,
  HostATMFormPageSection,
  IFrameBody,
  IFramePageSection,
  ListTabsBody,
  ListTabsPageSection,
  LogosBody,
  LogosPageSection,
  MapBody,
  MapPageSection,
  NavigationBody,
  NavigationPageSection,
  NewsletterBody,
  NewsletterPageSection,
  NumberInfoListBody,
  NumberInfoListPageSection,
  PageData,
  PageSection,
  PrismicBlogPost,
  PrismicConfig,
  PrismicHeaderItem,
  PrismicPage,
  ProductsListBody,
  ProductsListPageSection,
  SeparatorBody,
  SeparatorPageSection,
  StepPageSection,
  StepSectionBody,
  StepSectionWithMediaBody,
  StepWithMediaPageSection,
  TabsBody,
  TabsPageSection,
  TextWithMediaBody,
  TextWithMediaPageSection,
  TweetsListBody,
  TweetsListPageSection,
  VerifyTransactionBody,
  VerifyTransactionPageSection,
  VideoPageSection,
  VideoSectionBody,
} from './types';
import {
  getAlternateLanguagesUrlsMap,
  makePrismicClient,
  normalizeHref,
  queryAllPages,
} from './utils';
import {
  NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING,
  PRISMIC_BLOG_POSTS_CACHE_KEY,
  PRISMIC_CONFIG_CACHE_KEY,
  PRISMIC_PAGES_CACHE_KEY,
  PRISMIC_CACHE_OPTIONS,
  MAX_EXCERPT_LENGTH,
} from './consts';

export const getBlogPosts = async ({
  locale,
  ref,
}: {
  locale?: string;
  ref?: string;
} = {}): Promise<BlogPostData[]> => {
  const prismicLang = locale
    ? NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING[locale]
    : undefined;
  const lang = prismicLang || '*';

  if (locale && !prismicLang) {
    throw new Error(`Unknown locale passed: "${locale}"`);
  }

  return getCachedValueWithFallback({
    key: `${PRISMIC_BLOG_POSTS_CACHE_KEY}_${lang}`,
    fallback: async () => {
      const results = await queryAllPages(
        Prismic.predicates.at('document.type', 'blog_post'),
        {
          lang,
          fetchLinks: [
            'blog_post_author.name',
            'blog_post_author.slug',
            'category.title',
            'category.alternate_languages',
            'category.uid',
          ],
          ...(ref ? { ref } : {}),
        },
      );
      const blogPosts = cameliseDeep(results, [
        'data.content[]',
      ]) as PrismicBlogPost[];
      const alternateLanguagesUrlsMap = await getAlternateLanguagesUrlsMap(
        blogPosts,
        (slug) => slug && routes.newsDetails(slug),
      );
      const prismicClient = makePrismicClient();
      const categoriesIds = uniq(
        blogPosts.reduce<string[]>((result, { data: { categories } }) => {
          const ids = filter(
            (categories || []).map(({ category }) => category?.id),
          ) as string[];

          return [...result, ...ids];
        }, []),
      );
      const categoriesDocs = await Promise.all(
        categoriesIds.map((categoryId) =>
          (async () =>
            cameliseDeep(
              await prismicClient.queryFirst(
                Prismic.predicates.at('document.id', categoryId),
                {
                  lang: '*',
                },
              ),
            ) as DocumentWithAlternateLanguages)(),
        ),
      );
      const categoriesAlternateLanguagesUrlsMap = await getAlternateLanguagesUrlsMap(
        categoriesDocs,
        (slug) => slug && routes.newsCategory(slug),
        'uid',
      );

      return removeUndefinedFields(
        blogPosts
          .map(
            ({
              id,
              lang: postLang,
              firstPublicationDate,
              data: {
                author,
                categories,
                content,
                excerpt,
                featuredImage,
                slug,
                title,
                seoDescription,
                seoTitle,
                publicationDate,
              },
            }) => {
              const excerptAsText = RichText.asText(excerpt || []);
              const excerptValue = truncate(
                excerptAsText || RichText.asText(content || []),
                {
                  length: MAX_EXCERPT_LENGTH,
                },
              );

              return {
                id,
                seoTitle: seoTitle || undefined,
                seoDescription: seoDescription || excerptValue || undefined,
                publicationDate:
                  publicationDate ||
                  format(new Date(firstPublicationDate), 'yyyy-MM-dd'),
                lang: postLang,
                alternateLanguages: alternateLanguagesUrlsMap[id] || [],
                slug: slug || undefined,
                nextLocale: findKeyByValue(
                  NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING,
                  postLang,
                ),
                image: featuredImage?.url
                  ? {
                      url: featuredImage.url,
                      dimensions: featuredImage.dimensions,
                      alt: featuredImage.alt || undefined,
                    }
                  : undefined,
                title: title || undefined,
                content: content || undefined,
                excerpt: excerptValue,
                author: author
                  ? {
                      name: author?.data?.name || undefined,
                      slug: author?.data?.slug || undefined,
                    }
                  : undefined,
                categories:
                  categories?.reduce<BlogPostData['categories']>(
                    (result, { category }) => {
                      if (!category || !category.id || !category.data) {
                        return result;
                      }

                      return [
                        ...result,
                        {
                          id: category.id,
                          name: category.data.title || undefined,
                          slug: category.data.uid || undefined,
                          alternateLanguages:
                            categoriesAlternateLanguagesUrlsMap[category.id] ||
                            [],
                        },
                      ];
                    },
                    [],
                  ) || [],
              };
            },
          )
          .sort((a, b) => {
            const dateNow = new Date();
            const dateA = parse(a.publicationDate, 'yyyy-MM-dd', dateNow);
            const dateB = parse(b.publicationDate, 'yyyy-MM-dd', dateNow);

            return dateB.valueOf() - dateA.valueOf();
          }),
      );
    },
    cacheOptions: PRISMIC_CACHE_OPTIONS,
    forceCacheRefresh: process.env.npm_lifecycle_event !== 'build',
  });
};

const pageSectionMappers: MappedObject<
  (section: never, posts: BlogPostData[]) => PageSection | undefined
> = {
  hero: ({
    sliceType,
    primary: {
      heroDescription,
      heroTitle,
      videoUrl,
      background,
      spacing,
      sectionId,
      image,
      mobileImage,
    },
    items,
  }: HeroBody): HeroPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    title: heroTitle || undefined,
    videoUrl: videoUrl || undefined,
    description: heroDescription || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    sectionId: sectionId || undefined,
    image:
      image && image.url
        ? {
            dimensions: image.dimensions,
            url: image.url,
            alt: image.alt || undefined,
          }
        : undefined,
    mobileImage:
      mobileImage && mobileImage.url
        ? {
            dimensions: mobileImage.dimensions,
            url: mobileImage.url,
            alt: mobileImage.alt || undefined,
          }
        : undefined,
    links: items.reduce<HeroPageSection['links']>(
      (
        result,
        { buttonLink, buttonLabel, buttonMode, buttonModeType, buttonSize },
      ) => {
        if (buttonLink.linkType !== 'Web' || !buttonLink.url) {
          return result;
        }

        return [
          ...result,
          {
            label: buttonLabel || '',
            href: normalizeHref(buttonLink.url),
            mode: buttonMode || undefined,
            modeType: buttonModeType || undefined,
            size: buttonSize || undefined,
          },
        ];
      },
      [],
    ),
  }),
  map_section: ({
    sliceType,
    primary: {
      mapTitle,
      background,
      spacing,
      sectionId,
      showOnlySellAtmsByDefault,
    },
  }: MapBody): MapPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    mapTitle: mapTitle || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    showOnlySellAtmsByDefault: !!showOnlySellAtmsByDefault,
  }),
  logos: ({
    sliceType,
    items,
    primary: { logoTitle, background, spacing, sectionId },
  }: LogosBody): LogosPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    title: logoTitle || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    sectionId: sectionId || undefined,
    items: items.reduce<LogosPageSection['items']>(
      (result, { logoItem, logoUrl }) => {
        if (!logoItem || !logoItem.url) {
          return result;
        }

        return [
          ...result,
          {
            url:
              logoUrl?.linkType === 'Web' && logoUrl?.url
                ? logoUrl.url
                : undefined,
            logo: {
              dimensions: logoItem.dimensions,
              url: logoItem.url,
              alt: logoItem.alt || undefined,
            },
          },
        ];
      },
      [],
    ),
  }),
  features_section: ({
    items,
    primary: {
      background,
      spacing,
      featuresButton,
      featuresDescription,
      featuresTitle,
      buttonLabel,
      sectionId,
    },
    sliceType,
  }: FeaturesSectionBody): FeaturesPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    buttonLabel: buttonLabel || undefined,
    buttonUrl:
      featuresButton.linkType === 'Web' && featuresButton.url
        ? normalizeHref(featuresButton.url)
        : undefined,
    description: featuresDescription || undefined,
    title: featuresTitle || undefined,
    items: items.map(
      ({
        featuresItemDescription,
        featuresItemIcon,
        backgroundVariant,
        featuresItemTitle,
        featuresItemImage,
        subTitle,
      }) => ({
        key: uuidv4(),
        description: featuresItemDescription || undefined,
        icon: featuresItemIcon || undefined,
        background: backgroundVariant || undefined,
        title: featuresItemTitle || undefined,
        subTitle: subTitle || undefined,
        image: featuresItemImage?.url
          ? {
              dimensions: featuresItemImage.dimensions,
              url: featuresItemImage.url,
              alt: featuresItemImage.alt || undefined,
            }
          : undefined,
      }),
    ),
  }),
  cta_section: ({
    sliceType,
    primary: {
      ctaButtonLink,
      ctaButtonLabel,
      buttonIcon,
      ctaDescription,
      ctaStyle,
      ctaTitle,
      background,
      spacing,
      buttonMode,
      buttonModeType,
      buttonSize,
      sectionId,
    },
  }: CTASectionBody): CTAPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    title: ctaTitle || undefined,
    description: ctaDescription || undefined,
    style: ctaStyle || undefined,
    buttonLabel: ctaButtonLabel || undefined,
    sectionId: sectionId || undefined,
    buttonLink:
      ctaButtonLink.linkType === 'Web' && ctaButtonLink.url
        ? normalizeHref(ctaButtonLink.url)
        : undefined,
    buttonIcon: buttonIcon || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    mode: buttonMode || undefined,
    modeType: buttonModeType || undefined,
    size: buttonSize || undefined,
  }),
  step_section: ({
    sliceType,
    primary: { description1, title1, background, spacing, sectionId },
  }: StepSectionBody): StepPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    title: title1 || undefined,
    sectionId: sectionId || undefined,
    description: description1 || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
  }),
  tabs_section: ({
    sliceType,
    primary: { background, spacing, sectionId },
    items,
  }: TabsBody): TabsPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    background: background || undefined,
    sectionId: sectionId || undefined,
    spacing: spacing || undefined,
    items: Object.values(groupBy(items, ({ tabTitle }) => tabTitle)).reduce<
      TabsPageSection['items']
    >((result, itemsGroup) => {
      const [{ tabTitle }] = itemsGroup;

      if (!tabTitle) {
        return result;
      }

      return [
        ...result,
        {
          key: uuidv4(),
          title: tabTitle,
          items: itemsGroup.reduce<TabsPageSection['items'][0]['items']>(
            (
              nestedItemsResult,
              { tabItemDescription, tabItemImage, tabItemTitle },
            ) => {
              if (!tabItemTitle) {
                return nestedItemsResult;
              }

              return [
                ...nestedItemsResult,
                {
                  key: uuidv4(),
                  title: tabItemTitle,
                  description: tabItemDescription || undefined,
                  image:
                    tabItemImage && tabItemImage.url
                      ? {
                          dimensions: tabItemImage.dimensions,
                          url: tabItemImage.url,
                          alt: tabItemImage.alt || undefined,
                        }
                      : undefined,
                },
              ];
            },
            [],
          ),
        },
      ];
    }, []),
  }),
  separator: ({
    sliceType,
    primary: { background, spacing, sectionId },
  }: SeparatorBody): SeparatorPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
  }),
  step_section_with_media: ({
    sliceType,
    primary: { description1, title1, background, spacing, image, sectionId },
  }: StepSectionWithMediaBody): StepWithMediaPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    title: title1 || undefined,
    description: description1 || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    image:
      image && image.url
        ? {
            dimensions: image.dimensions,
            url: image.url,
            alt: image.alt || undefined,
          }
        : undefined,
  }),
  video_section: ({
    sliceType,
    primary: { background, description1, title1, spacing, videoUrl, sectionId },
  }: VideoSectionBody): VideoPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    title: title1 || undefined,
    description: description1 || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    videoUrl: videoUrl || undefined,
  }),
  text_with_media: ({
    items,
    sliceType,
    primary: { sectionId, isWide, withBackground, spacing },
  }: TextWithMediaBody): TextWithMediaPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    isWide: typeof isWide === 'boolean' ? isWide : true,
    spacing: spacing || undefined,
    withBackground: typeof withBackground === 'boolean' ? withBackground : true,
    items: items.map(
      ({
        title1,
        description1,
        image,
        firstLinkTitle,
        firstLinkUrl,
        secondLinkTitle,
        secondLinkUrl,
        alignImageToRight,
      }) => ({
        key: uuidv4(),
        title: title1 || undefined,
        description: description1 || undefined,
        image:
          image && image.url
            ? {
                dimensions: image.dimensions,
                url: image.url,
                alt: image.alt || undefined,
              }
            : undefined,
        alignImageToRight: !!alignImageToRight,
        links: [
          ...(firstLinkUrl?.linkType === 'Web' &&
          firstLinkUrl.url &&
          firstLinkTitle
            ? [
                {
                  key: uuidv4(),
                  title: firstLinkTitle,
                  href: normalizeHref(firstLinkUrl.url),
                },
              ]
            : []),
          ...(secondLinkUrl?.linkType === 'Web' &&
          secondLinkUrl.url &&
          secondLinkTitle
            ? [
                {
                  key: uuidv4(),
                  title: secondLinkTitle,
                  href: normalizeHref(secondLinkUrl.url),
                },
              ]
            : []),
        ],
      }),
    ),
  }),
  flexepin_form_hero: ({
    primary: {
      title1,
      spacing,
      background,
      buttonLabel,
      buttonLink,
      buttonMode,
      buttonModeType,
      buttonSize,
      description1,
      redirectUrlTemplate,
      maxAmount,
      minAmount,
      sectionId,
    },
    sliceType,
  }: FlexepinFormHeroBody): FlexepinFormHeroPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    title: title1 || undefined,
    spacing: spacing || undefined,
    background: background || undefined,
    description: description1 || undefined,
    href:
      buttonLink && buttonLink.linkType === 'Web' && buttonLink.url
        ? normalizeHref(buttonLink.url)
        : undefined,
    label: buttonLabel || undefined,
    mode: buttonMode || undefined,
    modeType: buttonModeType || undefined,
    size: buttonSize || undefined,
    redirectUrlTemplate: redirectUrlTemplate || undefined,
    minAmount: minAmount === null ? 100 : minAmount,
    maxAmount: maxAmount === null ? 200 : maxAmount,
  }),
  'e-transfer_form': ({
    sliceType,
    items,
    primary: { background, spacing, minAmount, sectionId },
  }: ETransferFormBody): ETransferFormPageSection | undefined => ({
    key: uuidv4(),
    sectionId: sectionId || undefined,
    minAmount: minAmount !== null ? minAmount : 20,
    type: sliceType,
    background: background || undefined,
    spacing: spacing || undefined,
    items: items.reduce<SelectItem[]>(
      (result, { paymentTitle, paymentUrlTemplate }) => {
        if (!paymentTitle || !paymentUrlTemplate) {
          return result;
        }

        return [
          ...result,
          {
            optionLabel: paymentTitle,
            value: paymentUrlTemplate,
          },
        ];
      },
      [],
    ),
  }),
  iframe: ({
    sliceType,
    primary: { iframeUrl, iframeTitle, sectionId },
  }: IFrameBody): IFramePageSection | undefined => {
    if (!iframeUrl || iframeUrl.linkType !== 'Web' || !iframeUrl.url) {
      return undefined;
    }

    return {
      key: uuidv4(),
      type: sliceType,
      sectionId: sectionId || undefined,
      url: normalizeHref(iframeUrl.url),
      title: iframeTitle || undefined,
    };
  },
  faq: ({
    sliceType,
    primary: { background, faqTitle, spacing, sectionId },
    items,
  }: FaqBody): FaqPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    title: faqTitle || undefined,
    items: items.reduce<FaqPageSection['items']>(
      (result, { faqQuestion, faqAnswer }) => {
        if (!faqQuestion) {
          return result;
        }

        return [
          ...result,
          {
            key: uuidv4(),
            question: faqQuestion,
            answer: faqAnswer || undefined,
          },
        ];
      },
      [],
    ),
  }),
  verify_transaction: ({
    sliceType,
    primary: { background, spacing, sectionId },
  }: VerifyTransactionBody): VerifyTransactionPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
  }),
  cms_content: ({
    sliceType,
    primary: { background, spacing, content, sectionId },
  }: CMSContentBody): CMSContentPageSection | undefined => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    content: content || undefined,
  }),
  host_atm_form: ({
    sliceType,
    primary: {
      background,
      spacing,
      title1,
      alignImageToRight,
      image,
      sectionId,
    },
  }: HostATMFormBody): HostATMFormPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    title: title1 || undefined,
    image:
      image && image.url
        ? {
            dimensions: image.dimensions,
            url: image.url,
            alt: image.alt || undefined,
          }
        : undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    alignImageToRight: alignImageToRight || false,
  }),
  number_info_list: ({
    sliceType,
    primary: { background, spacing, sectionId },
    items,
  }: NumberInfoListBody): NumberInfoListPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    items: items.map(
      ({ title1, description1, numericValue, numericValueColor }) => ({
        key: uuidv4(),
        title: title1 || undefined,
        description: description1 || undefined,
        color: numericValueColor || undefined,
        number: numericValue || undefined,
      }),
    ),
  }),
  list_tabs: ({
    sliceType,
    primary: { background, spacing, title1, sectionId },
    items,
  }: ListTabsBody): ListTabsPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    title: title1 || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    items: Object.values(groupBy(items, ({ tabName }) => tabName)).reduce<
      ListTabsPageSection['items']
    >((result, tabItemsGroup) => {
      const [{ tabName }] = tabItemsGroup;

      if (!tabName) {
        return result;
      }

      return [
        ...result,
        {
          key: uuidv4(),
          tabName: tabName || '',
          items: tabItemsGroup.map(({ itemContent }) => ({
            key: uuidv4(),
            content: itemContent || undefined,
          })),
        },
      ];
    }, []),
  }),
  tweets_list: ({
    sliceType,
    primary: { background, spacing, sectionId },
    items,
  }: TweetsListBody): TweetsListPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    items: items.reduce<TweetsListPageSection['items']>(
      (result, { tweetId }) => {
        if (!tweetId) {
          return result;
        }

        return [
          ...result,
          {
            key: uuidv4(),
            tweetId,
          },
        ];
      },
      [],
    ),
  }),
  navigation: ({
    sliceType,
    items,
    primary: { background, spacing, sectionId },
  }: NavigationBody): NavigationPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    items: items.reduce<NavigationPageSection['items']>(
      (result, { link, title1 }) => {
        if (!title1 || link?.linkType !== 'Web' || !link.url) {
          return result;
        }

        return [
          ...result,
          {
            title: title1,
            url: normalizeHref(link.url),
            key: uuidv4(),
          },
        ];
      },
      [],
    ),
  }),
  column_content: ({
    sliceType,
    items,
    primary: { background, spacing, sectionId },
  }: ColumnContentBody): ColumnContentPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    items: items.map(({ content, title1 }) => ({
      key: uuidv4(),
      title: title1 || undefined,
      content: content || undefined,
    })),
  }),
  products_list: ({
    sliceType,
    items,
    primary: { background, spacing, title1, sectionId },
  }: ProductsListBody): ProductsListPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    title: title1 || undefined,
    items: items.map(
      ({ title1: itemTitle, description1, link, linkTitle }) => ({
        key: uuidv4(),
        title: itemTitle || undefined,
        description: description1 || undefined,
        url:
          link?.linkType === 'Web' && link.url
            ? normalizeHref(link.url)
            : undefined,
        linkTitle: linkTitle || undefined,
      }),
    ),
  }),
  newsletter: ({
    items,
    sliceType,
    primary: { background, spacing, sectionId, title1 },
  }: NewsletterBody): NewsletterPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    title: title1 || undefined,
    items: items.map(({ agreementLabel }) => ({
      key: uuidv4(),
      agreementLabel,
    })),
  }),
  featured_blog_posts: (
    {
      sliceType,
      primary: {
        background,
        spacing,
        sectionId,
        title1,
        description,
        ctaLink,
        ctaText,
      },
    }: FeaturedBlogPostsBody,
    blogPosts,
  ): FeaturedBlogPostsPageSection => ({
    key: uuidv4(),
    type: sliceType,
    sectionId: sectionId || undefined,
    background: background || undefined,
    spacing: spacing || undefined,
    title: title1 || undefined,
    description: description || undefined,
    ctaLink:
      ctaLink?.linkType === 'Web' && ctaLink.url
        ? normalizeHref(ctaLink.url)
        : undefined,
    ctaText: ctaText || undefined,
    posts: take(blogPosts, 3).map(
      ({ title: postTitle, slug, image, id, excerpt }) => ({
        key: id,
        title: postTitle,
        description: excerpt,
        href: routes.newsDetails(slug || ''),
        image:
          image && image.url
            ? {
                dimensions: image.dimensions,
                url: image.url,
                alt: image.alt || undefined,
              }
            : undefined,
      }),
    ),
  }),
};

export const getPages = async ({
  locale,
  ref,
}: {
  locale?: string;
  ref?: string;
} = {}): Promise<PageData[]> => {
  const prismicLang = locale
    ? NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING[locale]
    : undefined;
  const lang = prismicLang || '*';

  if (locale && !prismicLang) {
    throw new Error(`Unknown locale passed: "${locale}"`);
  }

  return getCachedValueWithFallback({
    key: `${PRISMIC_PAGES_CACHE_KEY}_${lang}`,
    fallback: async () => {
      const results = await queryAllPages(
        Prismic.predicates.at('document.type', 'page'),
        {
          lang,
          ...(ref ? { ref } : {}),
        },
      );
      const pages = cameliseDeep(results, [
        'data.body[].items[].faqAnswer[]',
        'data.body[].primary.description1[]',
        'data.body[].items[].description1[]',
        'data.body[].items[].tabItemDescription[]',
        'data.body[].items[].featuresItemDescription[]',
        'data.body[].items[].subTitle[]',
      ]) as PrismicPage[];
      const alternateLanguagesUrlsMap = await getAlternateLanguagesUrlsMap(
        pages,
      );
      const blogPosts = await getBlogPosts({ locale, ref });

      return removeUndefinedFields(
        pages.map<PageData>(
          ({
            id,
            lang: pageLang,
            data: {
              openGrapPicture,
              body,
              description,
              isHome,
              seoDescription,
              seoTitle,
              slug,
              title,
              code,
            },
          }) => ({
            id,
            alternateLanguages: alternateLanguagesUrlsMap[id] || [],
            lang: pageLang,
            openGraphPictureUrl: openGrapPicture?.url || undefined,
            nextLocale: findKeyByValue(
              NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING,
              pageLang,
            ),
            isHome,
            slug: slug || undefined,
            title: title || undefined,
            seoTitle: seoTitle || undefined,
            description: description || undefined,
            seoDescription: seoDescription || undefined,
            code: code ? RichText.asText(code) : undefined,
            pageSections: body.reduce<PageSection[]>((result, bodyItem) => {
              const mapper = pageSectionMappers[bodyItem.sliceType];

              if (!mapper) {
                return result;
              }

              const pageSection = mapper(bodyItem as never, blogPosts);

              if (!pageSection) {
                return result;
              }

              return [...result, pageSection];
            }, []),
          }),
        ),
      );
    },
    cacheOptions: PRISMIC_CACHE_OPTIONS,
    forceCacheRefresh: process.env.npm_lifecycle_event !== 'build',
  });
};

const recursiveGetHeaderItems = (
  currentNavItemOrSubItem: NavItem | SubMenuItem,
  currentHeaderItem: PrismicHeaderItem,
  allHeaderItems: PrismicHeaderItem[],
): void => {
  if (!currentHeaderItem.data.items.length) {
    return;
  }

  const itemsArrayRef: SubMenuItem[] | undefined =
    get(currentNavItemOrSubItem, 'subMenu') ||
    get(currentNavItemOrSubItem, 'items');

  if (!itemsArrayRef) {
    return;
  }

  currentHeaderItem.data.items.forEach(({ subItem }) => {
    if (!subItem) {
      return;
    }

    const { id } = subItem;
    const subItemDetails = allHeaderItems.find(
      (headerItem) => headerItem.id === id,
    );

    if (!subItemDetails) {
      return;
    }

    const {
      data: { link, title },
    } = subItemDetails;

    if (!link || link.linkType !== 'Web' || !link.url) {
      return;
    }

    const normalizedHref = normalizeHref(link.url);
    const menuSubItem: SubMenuItem = {
      href: normalizedHref,
      label: title || normalizedHref,
      items: [],
    };

    itemsArrayRef.push(menuSubItem);

    recursiveGetHeaderItems(menuSubItem, subItemDetails, allHeaderItems);
  });
};

export const getPrismicConfig = async ({
  locale,
  ref,
}: {
  locale?: string;
  ref?: string;
} = {}): Promise<ConfigData> => {
  const prismicLang = locale
    ? NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING[locale]
    : undefined;
  const lang = prismicLang || '*';

  if (locale && !prismicLang) {
    throw new Error(`Unknown locale passed: "${locale}"`);
  }

  return getCachedValueWithFallback({
    key: `${PRISMIC_CONFIG_CACHE_KEY}_${lang}`,
    fallback: async () => {
      const prismicClient = makePrismicClient();
      const [defaultConfig, langConfig] = await Promise.all([
        prismicClient.getSingle('config', {
          ...(ref ? { ref } : {}),
        }),
        prismicClient.getSingle('config', {
          lang,
          ...(ref ? { ref } : {}),
        }),
      ]);
      const resultConfig = langConfig || defaultConfig;

      if (!resultConfig) {
        throw new Error(
          `Could not find Prismic config. Make sure you have added entity of type "config" to Prismic.`,
        );
      }

      const {
        data: {
          blogAdImage,
          blogAdUrl,
          headerItems,
          footerColumns,
          disclaimer,
          footerCopy,
          topBannerContent,
          topBannerActive,
          helpDescription,
          facebookLink,
          instagramLink,
          twitterLink,
          twitterSourceType,
          twitterScreenName,
          hostAtmLink,
          hostAtmLinkLabel,
        },
      } = cameliseDeep(resultConfig, [
        'data.footerColumns[].columnContent[]',
        'data.topBannerContent[]',
      ]) as PrismicConfig;
      const results = await queryAllPages(
        Prismic.predicates.at('document.type', 'header_item'),
        {
          lang: '*',
        },
      );

      const allHeaderItems = cameliseDeep(results) as PrismicHeaderItem[];
      const headerNavItems = headerItems.reduce<NavItem[]>(
        (result, { headerItem }) => {
          if (!headerItem) {
            return result;
          }

          const headerItemDetails = allHeaderItems.find(
            ({ id }) => id === headerItem.id,
          );

          if (
            !headerItemDetails ||
            !headerItemDetails.data.link ||
            headerItemDetails.data.link.linkType !== 'Web' ||
            !headerItemDetails.data.link.url
          ) {
            return result;
          }

          const {
            id,
            data: { link, title },
          } = headerItemDetails;

          if (!link.url) {
            return result;
          }

          const normalizedHref = normalizeHref(link.url);
          const navItem: NavItem = {
            href: normalizedHref,
            key: id,
            label: title || normalizedHref,
            subMenu: [],
          };

          recursiveGetHeaderItems(navItem, headerItemDetails, allHeaderItems);

          return [...result, navItem];
        },
        [],
      );

      return removeUndefinedFields({
        blogAdImage: blogAdImage?.url
          ? {
              dimensions: blogAdImage.dimensions,
              url: blogAdImage.url,
              alt: blogAdImage.alt || undefined,
            }
          : undefined,
        blogAdUrl:
          blogAdUrl?.linkType === 'Web' && blogAdUrl.url
            ? normalizeHref(blogAdUrl.url)
            : undefined,
        headerItems: headerNavItems,
        footerColumns: footerColumns.map(({ columnContent, columnTitle }) => ({
          key: uuidv4(),
          title: columnTitle || '',
          content: columnContent || undefined,
        })),
        disclaimer: disclaimer || undefined,
        footerCopy: footerCopy || undefined,
        topBannerContent: topBannerContent || undefined,
        topBannerActive: !!topBannerActive,
        helpDescription: helpDescription || undefined,
        facebookLink:
          facebookLink?.linkType === 'Web' && facebookLink.url
            ? normalizeHref(facebookLink.url)
            : undefined,
        instagramLink:
          instagramLink?.linkType === 'Web' && instagramLink.url
            ? normalizeHref(instagramLink.url)
            : undefined,
        twitterLink:
          twitterLink?.linkType === 'Web' && twitterLink.url
            ? normalizeHref(twitterLink.url)
            : undefined,
        twitterSourceType: twitterSourceType || undefined,
        twitterScreenName: twitterScreenName || undefined,
        hostAtmLinkLabel: hostAtmLinkLabel || undefined,
        hostAtmLink:
          hostAtmLink?.linkType === 'Web' && hostAtmLink.url
            ? normalizeHref(hostAtmLink.url)
            : undefined,
      });
    },
    cacheOptions: PRISMIC_CACHE_OPTIONS,
    forceCacheRefresh: process.env.npm_lifecycle_event !== 'build',
  });
};
