import React, { Fragment } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { Text } from 'theme-ui';

import Inner from 'components/styles/Inner';
import { StyledNotFoundPageWrapper } from './styles';
import { Page404Props } from './types';

const Error404Page: NextPage<Page404Props> = ({ text }) => (
  <Fragment>
    <Head>
      <title>
        Localcoin | Bitcoin ATM - Buy and Sell Bitcoin with Cash from
        Canada&apos;s Largest Bitcoin ATM Network
      </title>
    </Head>
    <StyledNotFoundPageWrapper>
      <Inner>
        <Text variant="heading2" as="h2">
          {text || (
            <React.Fragment>
              Sorry! That page doesn&apos;t seem to exist.
            </React.Fragment>
          )}
        </Text>
      </Inner>
    </StyledNotFoundPageWrapper>
  </Fragment>
);

export { getStaticProps } from './utils';

export default Error404Page;
