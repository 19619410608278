import { CacheEntry, CacheEntryOptions, CacheProvider } from './types';

interface MemoryCacheEntry {
  expires?: Date;
  value: unknown;
}

export default class MemoryCacheProvider implements CacheProvider {
  private memoryCache: Partial<MappedObject<MemoryCacheEntry>> = {};

  async get(key: string): Promise<unknown> {
    const cacheEntry = this.memoryCache[key];

    if (!cacheEntry) {
      return undefined;
    }

    const { value, expires } = cacheEntry;
    const hasExpired =
      expires !== undefined ? expires.valueOf() < Date.now() : false;

    if (hasExpired) {
      await this.remove(key);

      return undefined;
    }

    return value;
  }

  async set(
    key: string,
    value: unknown,
    options?: CacheEntryOptions,
  ): Promise<void> {
    const { maxAge } = options || {};
    const expires =
      maxAge !== undefined ? new Date(Date.now() + maxAge) : undefined;

    this.memoryCache[key] = {
      expires,
      value,
    };
  }

  async remove(key: string): Promise<void> {
    delete this.memoryCache[key];
  }

  async removeAll(): Promise<void> {
    this.memoryCache = {};
  }

  async peek(): Promise<CacheEntry[]> {
    return Object.entries(this.memoryCache).reduce<CacheEntry[]>(
      (result, [key, memoryCacheEntry]) => {
        if (!memoryCacheEntry) {
          return result;
        }

        const { value } = memoryCacheEntry;

        return [
          ...result,
          {
            key,
            value,
          },
        ];
      },
      [],
    );
  }
}
